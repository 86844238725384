import api from "@/api";

const content = {
  list(params) {
    return api.get(`/content`, {
      params: params,
    });
  },
};

export default content;
