<template>
  <div id="content" v-loading="loading">
    <div class="cm-content">
      <h-content :params="params" @change="handleSearch"></h-content>
      <br />
      <el-table :data="data">
        <el-table-column label="发送ID" prop="source_id"></el-table-column>
        <el-table-column label="接收ID" prop="target_id"></el-table-column>
        <el-table-column label="发送者" prop="source_name"></el-table-column>
        <el-table-column label="接收者" prop="target_name"></el-table-column>
        <el-table-column label="内容" prop="content">
          <template slot-scope="scope">
            <div v-if="scope.row.object_name === 'RC:ImgMsg'">
              <el-button type="text" @click="handleViewImage(scope.row.content)"
                >查看图片</el-button
              >
            </div>
            <div v-else>
              <el-popover
                v-if="handleGetContent(scope.row).length > 20"
                placement="top-start"
                width="180"
                trigger="hover"
                :content="handleGetContent(scope.row)"
              >
                <div slot="reference">
                  {{ handleGetContent(scope.row).substr(0, 20) }}...
                </div>
              </el-popover>
              <div v-else>
                {{ handleGetContent(scope.row) }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会话类型" prop="conversation_type">
          <template slot-scope="scope">
            {{ scope.row.conversation_type | formatConversationType }}
          </template>
        </el-table-column>
        <el-table-column label="发送时间" prop="message_send_time">
          <template slot-scope="scope">
            {{ scope.row.message_send_time | formatTimestamp }}
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-image ref="dialog"></d-image>
    </div>
  </div>
</template>

<script>
import HContent from "@/components/headers/HContent";
import DImage from "@/components/dialogs/DImage";

import content from "@/api/content";

export default {
  name: "message",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HContent,
    DImage,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      this.loading = true;
      content.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "content",
          query: params,
        })
        .catch(() => {});
    },
    handleGetContent(val) {
      let msg = JSON.parse(val.content);
      if (val.object_name === "RC:TxtMsg") {
        return msg.content;
      } else if (val.object_name === "RC:StkMsg") {
        return msg.digest;
      } else if (val.object_name === "RC:ImgMsg") {
        return msg.imageUri;
      }
      return val.content;
    },
    handleViewImage(val) {
      let msg = JSON.parse(val);
      this.$refs.dialog.handleShow([msg.imageUri]);
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.params.page = 1;
      this.$router
        .push({
          name: "content",
          query: val,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "content") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#content {
  display: flex;
  height: 100%;
}
</style>
