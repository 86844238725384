<template>
  <div id="h-content" class="h-search">
    <el-input
      class="mb10 fl wid-15 mr10"
      size="small"
      clearable
      v-model="source_id"
      placeholder="发送者ID"
      @change="handleSourceIDChange"
    ></el-input>
    <el-input
      class="mb10 fl wid-15 mr10"
      size="small"
      clearable
      v-model="target_id"
      placeholder="接收者ID"
      @change="handleTargetIDChange"
    ></el-input>
    <el-button
      class="mb10 fl wid-5"
      size="small"
      type="primary"
      @click="handleSearch"
    >
      查询
    </el-button>
  </div>
</template>

<script>
export default {
  name: "h-content",
  data() {
    return {
      data: {},
      source_id: "",
      target_id: "",
    };
  },
  props: {
    params: {
      type: Object,
      default: function () {
        return {
          source_id: "",
          target_id: "",
        };
      },
    },
  },
  created() {
    if (this.params.source_id) {
      this.source_id = this.params.source_id;
    }
    if (this.params.target_id) {
      this.target_id = this.params.target_id;
    }
  },
  methods: {
    handleSourceIDChange(val) {
      if (val) {
        this.data["source_id"] = val;
      } else {
        delete this.data.source_id;
      }
    },
    handleTargetIDChange(val) {
      if (val) {
        this.data["target_id"] = val;
      } else {
        delete this.data.target_id;
      }
    },
    handleSearch() {
      this.$emit("change", this.data);
    },
  },
};
</script>
